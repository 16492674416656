import { defineStore } from 'pinia'
import { useSiteStore } from "~/store/site";
import { useUiStore } from "~/store/ui";

export const useSmartrmailEmbeddedStore = defineStore({
  id: "smartrmail",
  state: () => ({
    embeddedUrl: "",
    reAuthModalOpen: false,
  }),
  getters: {
    isEmbedded() {
      if(useUiStore().loadingPreflight) { return false }

      return useSiteStore().currentSite.smartrmail_embedded
    },
    isReAuthRequired() {
      if(useUiStore().loadingPreflight) { return true }

      return useSiteStore().currentSite.force_smartrmail_auth_scopes_reauth
    }
  },
  actions: {
    markSmartrmailEmbedded() {
      useSiteStore().currentSite.smartrmail_embedded = true
    },
    setReAuthModalOpen(open) {
      this.reAuthModalOpen = open
    },
    setEmbeddedUrl(url) {
      this.embeddedUrl = url
    },

    async fetchEmbeddedUrl(path = "") {
      try {
        const response = await this.$nuxt.$axios.$get(`${process.env.API_V1_URL}/smartrmail/embedded_url?path=${path}`)

        this.setEmbeddedUrl(response.data.url)
      } catch (error) {
        this.$nuxt.$toasts.add({
          type: 'error',
          title: 'Failed to load SmartrMail',
          subtitle: (
            error.response.data.errors ?
              error.response.data.errors?.join(".\n") :
              'Please try again later.'
          ),
          bottomMessage: 'Please contact support for the details',
        })
      }
    },
  },
})
